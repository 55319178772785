import React from 'react';

import Layout from '../components/Layout';
import Intro from '../components/Intro';
import Appointment from '../components/Appointment';
import FAQ from '../components/FAQ';

const IndexPage = () => {
  return (
    <Layout>
      <Intro />
      <Appointment />
      <FAQ />
    </Layout>
  );
};

export default IndexPage;
