import React from 'react';
import styled from 'styled-components';

import Container from '../components/Container';
import Text from '../components/Text';
import Title from '../components/Title';
import ContentWrapper from '../components/ContentWrapper';
import SectionWrapper from '../components/SectionWrapper';
import Accordions from './Accordions';

const AccordionsWrapper = styled.div`
  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 0 0 0 15rem;
  }
`;

const FAQ = () => {
  const items = [
    {
      title:
        'Hoe kom ik in aanmerking voor de nieuwe tijdelijke regeling voor zelfstandig ondernemers (Tozo)?',
      text:
        'Zelfstandig ondernemers die in de financiële problemen komen door de coronacrisis, kunnen aanspraak maken op een tijdelijke uitkering die ingaat vanaf 1 maart. Deze uitkering vult je inkomen drie maanden lang aan tot bijstandsniveau. Als je woont in de gemeente Amsterdam, dan moet je de checklist invullen op de site van de gemeente. https://www.amsterdam.nl/ondernemen/ondersteuning/sterker-ondernemen/ Binnen een week ontvang je het aanvraagformulier voor de regeling. Wil je vast weten of je voldoet aan de voorwaarden? Kijk dan op de website van de Rijksoverheid. https://www.rijksoverheid.nl/onderwerpen/coronavirus-covid-19/veelgestelde-vragen-per-onderwerp/financiele-regelingen/tozo Belangrijk om te weten is dat je niets hoeft terug te betalen, tenzij achteraf blijkt dat je geen recht had op de uitkering.',
    },
    {
      title:
        'Ik ontvang studiefinanciering, maar ben ook zelfstandig ondernemer. Kan ik aanspraak maken op de Tozo regeling?',
      text:
        'Als je inkomen uit studiefinanciering boven het sociaal minimum zit dan kom je niet in aanmerking voor de Tozo regeling. Zie hier wat het sociaal minimum is voor jouw situatie: https://www.uwv.nl/particulieren/bedragen/detail/sociaal-minimum Wanneer je nog niet maximaal leent kan de lening worden verhoogd met terugwerkende kracht (tot de start van het studiejaar). Daarnaast kun je ook collegegeldkrediet aanvragen. Als dit niet voldoende is kun je contact opnemen met DUO, elke werkdag tussen 09.00 en 17.00 op telefoonnummer 050-599 77 55. DUO geeft aan zo soepel mogelijk om te gaan met je situatie. ',
    },
    {
      title:
        'Wat moet ik doen als ik door de coronacrisis de huur van mijn woning niet kan betalen?',
      text:
        'Als je het kunt missen, betaal dan minstens een gedeelte van je huur. Het is belangrijk om hierover in gesprek te gaan met je verhuurder. Dit kun je doen door een mail te sturen en je situatie uit te leggen. Ook kun je gelijk een realistisch voorstel doen voor een betalingsregeling of uitstel. Lukt het niet en heb je hulp nodig bij het opstellen van je mail? Stichting !WOON kan je gratis helpen bij het contact met je verhuurder. https://www.wooninfo.nl/ ',
    },
    {
      title:
        'Wat moet ik doen als ik de huur van mijn bedrijf niet kan betalen? ',
      text:
        'Het is belangrijk om zo snel mogelijk in gesprek te gaan met de verhuurder. Uit de media blijkt dat het aanvragen van opschorting/huurverlaging complex is. https://nos.nl/artikel/2328892-de-huur-betalen-als-je-omzet-weg-is-betalen-of-je-riskeert-ontruiming.html Op de site van de gemeente staat veel informatie over dit probleem: https://www.amsterdam.nl/ondernemen/veelgestelde-vragen-corona-ondernemers/huurrechten/. Koninklijke Horeca Nederland helpt je bij het opstellen van een modelbrief om vrijstelling te vragen en ook helpt Horeca maatwerk bij het gesprek met de verhuurder. https://www.khn.nl/kennis/hurkorting-of-huurprijsverlaging-aanvragen',
    },
    {
      title:
        ' Kan ik als (zelfstandig) ondernemer een lening aanvragen om de komende maanden te overbruggen? ',
      text:
        'Je kunt als zelfstandig ondernemer in aanmerking komen voor een lening voor doorlopende bedrijfslasten van maximaal €10.157 tegen een rente van 2%. Als je de checklist invult op de website van de gemeente https://www.amsterdam.nl/ondernemen/ondersteuning/sterker-ondernemen/, krijg je binnen een week een aanvraagformulier toegestuurd via de mail. Via dit formulier kun je deze lening aanvragen. Als je ondernemer bent in en van de getroffen sectoren https://www.rvo.nl/subsidie-en-financieringswijzer/tegemoetkoming-schade-covid-19/vastgestelde-sbi-codes heb je misschien recht op de Tegemoetkoming Ondernemers Getroffen Sectoren (TOGS). Dit is een eenmalige gift van €4000 euro die de overheid uitkeert aan ondernemers in onder andere de evenementenbranche, podiumkunsten en horeca. Zie hier de voorwaarden: https://www.rijksoverheid.nl/onderwerpen/coronavirus-covid-19/veelgestelde-vragen-per-onderwerp/financiele-regelingen/tegemoetkoming-ondernemers-getroffen-sectoren-togs en vraag de TOGS hier aan: https://www.rvo.nl/subsidie-en-financieringswijzer/tegemoetkoming-schade-covid-19/aanvraagproces/aanvraagprocedure. Ook kun je een overbruggingskrediet aanvragen bij Qredits https://qredits.nl/.',
    },
    {
      title: 'Hoe stel ik mijn betaling aan de Belastingdienst uit?',
      text:
        'Het is mogelijk om een Bijzonder uitstel van betaling aan te vragen bij de belastingdienst. Dit kun je online doen: https://www.belastingdienst.nl/wps/wcm/connect/nl/ondernemers/content/hoe-vraag-ik-voor-3-maanden-bijzonder-uitstel-van-betaling-aan-vanwege-de-coronacrisis. De aanvraag doe je nadat je aangifte hebt gedaan en een aanslag hebt ontvangen. Dit geldt voor inkomstenbelasting, vennootschapsbelasting, omzetbelasting en loonheffingen. Wanneer de Belastingdienst het formulier ontvangt, hoef je drie maanden lang je aanslagen niet te betalen.',
    },
    {
      title: 'Hoe kan ik mijn personeel uitbetalen de komende maanden?',
      text:
        ' Bij minstens 20% verwachte omzetverlies kun je als werkgever voor een periode van 3 maanden tegemoetkoming in de loonkosten aanvragen. De hoogte van de tegemoetkoming is afhankelijk van het omzetverlies en kan maximaal 90% van de loonsom betreffen. De NOW loketten zijn nu open.',
    },
    {
      title:
        'Wanneer mogen de clubs weer open en kunnen we weer aan het werk? ',
      text:
        'Bijeenkomsten met meer dan 100 personen en groepsvorming van 3 personen of meer, waarbij geen anderhalve meter afstand gehanteerd wordt, is verboden tot tenminste 28 april. Evenementen met een vergunnings- en meldplicht zijn verboden tot 1 juni. De verwachting is dat het kabinet eind april met meer informatie komt over groepsvorming en kleine bijeenkomsten tot 100 personen. Wanneer evenementen en bijeenkomsten weer mogen plaatsvinden is nog geheel onduidelijk en hangt af van het verloop van de coronacrisis. ',
    },
  ];

  return (
    <Container>
      <SectionWrapper>
        <ContentWrapper>
          <Title>Veelgestelde vragen</Title>
          <Text>
            <p>
              Wellicht is je vraag al eerder gesteld en staat ‘ie tussen onze
              veelgestelde vragen.
            </p>
          </Text>
        </ContentWrapper>
        <AccordionsWrapper>
          <Accordions items={items} />
        </AccordionsWrapper>
      </SectionWrapper>
    </Container>
  );
};

export default FAQ;
