import React, { useState } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import Linkify from 'react-linkify';

import Text from './Text';
import Plus from '../../static/images/plus.svg';

const Wrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid black;
  padding: 2rem 0;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding: 3.5rem 0;
  }

  &:first-child {
    padding: 0 0 2rem;
    @media (${({ theme }) => theme.respondTo.desktop}) {
      padding: 0 0 3.5rem;
    }
  }
`;

const Title = styled.h3`
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 6.5rem;
  cursor: pointer;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    padding-right: 3.5rem;
    font-size: 1.4rem;
  }

  ${({ theme, alt }) =>
    alt &&
    `
    span:nth-child(1) {
      position: absolute;
    }
  
    span:nth-child(2) {
      display: block;
      font-family: ${theme.fontFamily};
      text-transform: none;
      font-weight: normal;
      padding-left: 11rem;
      padding-right: 3.5rem;
      
      @media (${theme.respondTo.desktop}) {
        font-size: 2rem;
        padding-left: 22rem;
        padding-right: 3.5rem;
      }
    }
  `};

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transition: all 0.25s;
    transform: ${({ isOpen }) =>
      isOpen ? 'translateY(-50%) rotate(45deg)' : 'translateY(-50%)'};
  }
`;

const StyledText = styled(Text)`
  padding: ${({ alt }) => (alt ? '2rem 0 0 0;' : '2rem 0 0')};

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding: ${({ alt }) => (alt ? '3.5rem 0 0 22rem;' : '3.5rem 0 0')};
    font-size: 1.8rem;
  }

  a {
    text-decoration: underline;
  }
`;

const Accordion = ({ title, subtitle, text, alt }) => {
  const componentDecorator = (href, text, key) => (
    <a
      href={href}
      key={key}
      style={{ wordBreak: 'break-all' }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );

  const [isOpen, setIsOpen] = useState(false);
  return (
    <Wrapper>
      <Title
        alt={alt ? 1 : 0}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{title}</span>
        <span>{subtitle}</span>
        <SVG src={Plus} />
      </Title>
      {isOpen && (
        <StyledText alt={alt}>
          <Linkify componentDecorator={componentDecorator}>
            <p>{text}</p>
          </Linkify>
        </StyledText>
      )}
    </Wrapper>
  );
};

export default Accordion;
