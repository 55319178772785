import React from 'react';
import styled from 'styled-components';

import Container from '../components/Container';
import Text from '../components/Text';
import Title from '../components/Title';
import ContentWrapper from '../components/ContentWrapper';
import Hero from '../../static/images/hero.jpg';
import SectionWrapper from '../components/SectionWrapper';
import Accordions from '../components/Accordions';

const Img = styled.img`
  @media (${({ theme }) => theme.respondTo.mobile}) {
    order: -1;
    margin: -28rem 0 5rem;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: -36rem 0 0;
  }
`;

const Intro = () => {
  const items = [
    {
      title: 'Legal',
      subtitle: 'Arbeidsrecht, Huur',
      text:
        'Dit spreekuur is gericht op arbeidsrecht en huurrecht (zakelijk en privé). Stel hier je vraag over jouw situatie rondom personeel, salarissen, arbeidsovereenkomsten, huurbetalingen en gerelateerde onderwerpen.',
    },
    {
      title: 'Legal',
      subtitle:
        'Overig (lopende contracten, onderneming, incasso, vergunningen etc.)',
      text:
        'Tijdens dit spreekuur kun je vragen stellen over juridische kwesties die niet te maken hebben met arbeidsrecht en huurrecht. Meld je aan als je bijvoorbeeld wilt weten hoe het zit met lopende contracten, je onderneming, incasso’s en vergunningen.',
    },
    {
      title: 'Insurance',
      subtitle: 'Alles over verzekeringen',
      text:
        'Als je vragen hebt over verzekeringen (zakelijk en privé) meld je dan hier aan.',
    },
    {
      title: 'Finance',
      subtitle: 'Administratie, leningen, uitkeringen',
      text:
        'Bij dit spreekuur kun je alles vragen over financiële administratie, studiefinanciering, leningen en uitkeringen. Wil je bijvoorbeeld een lening aanvragen, maar je weet niet waar en hoe? Of weet je niet hoe je aanspraak kunt maken op een uitkering? Meld je dan hier aan.',
    },
    {
      title: 'Tax',
      subtitle: 'Fiscaal, belastingdienst',
      text:
        'Stel al je fiscale en belastinggerelateerde vragen tijdens dit spreekuur. Voorbeelden zijn informatie betreffende uitstel van betalingen aanvragen, maar ook met hele specifieke technische vragen ben je hier aan het juiste adres.',
    },
    {
      title: 'Overig',
      subtitle: 'Strategie, coaching, creatief, online etc.',
      text:
        'Heb je vragen die niet gaan over juridische kwesties, belasting, verzekeringen en financiën? Meld je dan aan voor dit spreekuur. De experts kunnen advies geven over onder andere strategie, coaching, online profilering en creatieve kwesties.',
    },
  ];

  return (
    <Container>
      <SectionWrapper>
        <ContentWrapper>
          <Title>Ok, maar hoe kunnen jullie mij helpen?</Title>
          <Text>
            <p>
              Vanaf deze week organiseren we verschillende spreekuren om je
              vragen te beantwoorden. Meld je aan en laat ons weten waarbij je
              ondersteuning kunt gebruiken.
            </p>
            <p>
              Na jouw aanmelding krijg je een tijdslot toegekend waarin je
              persoonlijk jouw vragen aan een expert kunt stellen. De experts
              doen dit vrijwillig, dus deelnemen is helemaal gratis.
            </p>
          </Text>
          <Accordions alt items={items} />
        </ContentWrapper>
        <Img src={Hero} />
      </SectionWrapper>
    </Container>
  );
};

export default Intro;
