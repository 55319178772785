import React from 'react';
import styled from 'styled-components';
import Accordion from './Accordion';

const Wrapper = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 5rem 0 0;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: ${({ alt }) => (alt ? '4rem 0 0' : '0')};
  }
`;

const Accordions = ({ items, alt }) => {
  return (
    <Wrapper alt={alt ? 1 : 0}>
      {items.map(item => (
        <Accordion
          alt={alt ? 1 : 0}
          key={item.title + item.text}
          title={item.title}
          subtitle={item.subtitle}
          text={item.text}
        />
      ))}
    </Wrapper>
  );
};

export default Accordions;
