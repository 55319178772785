import React, { useState } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import emailjs from 'emailjs-com';
import Field from './Field';
import Button from './Button';
import Arrow from '../../static/images/arrow-right.svg';
import ArrowDown from '../../static/images/arrow-down.svg';

const StyledForm = styled.form`
  width: 50rem;
  margin: 2.4rem 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 0 0 0 10rem;
  }
`;

const Form = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [sent, setSent] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();

    setIsClicked(true);

    const { name, email, type, questions, activities } = e.target;

    const data = {
      name: name.value,
      email: email.value,
      type: type.value,
      questions: questions.value,
      activities: activities.value,
    };

    emailjs
      .send(
        'smtp.mail.pcextreme.nl',
        'template_ZHP3Z1ma',
        data,
        'user_Mt4Xu3ZBLtBrnBel4wsg6'
      )
      .then(
        result => {
          setSent(true);
        },
        error => {
          setErrors(error.text);
          setIsClicked(false);
        }
      );
  };

  return (
    <StyledForm onSubmit={e => handleSubmit(e)}>
      <Field>
        <input required type="text" name="name" />
        <label htmlFor="name">Naam</label>
      </Field>
      <Field>
        <input required type="email" name="email" />
        <label htmlFor="email">E-mail</label>
      </Field>
      <Field>
        <select name="type" required defaultValue="">
          <option value="" disabled>
            Type spreekuur
          </option>
          <option value="Legal">Legal - arbeidsrecht, huur</option>
          <option value="Legal">Legal - overig</option>
          <option value="Insurance">Insurance</option>
          <option value="Finance">Finance</option>
          <option value="Tax">Tax</option>
          <option value="Overig">Overig</option>
        </select>
        <SVG src={ArrowDown} />
      </Field>
      <Field select>
        <input required type="text" name="questions" />
        <label htmlFor="questions">Omschrijf je vragen</label>
      </Field>
      <Field big>
        <textarea required maxLength="100" rows="5" name="activities" />
        <label htmlFor="activities">Omschrijf je werkzaamheden</label>
      </Field>
      {sent ? (
        <p style={{ margin: '2rem auto 0 0' }}>
          Je aanmelding is verzonden! Je hoort zo spoedig mogelijk van ons.
        </p>
      ) : (
        <Button
          style={{ marginTop: '2rem' }}
          disabled={isClicked}
          white
          type="submit"
        >
          <SVG src={Arrow} />
          {isClicked ? 'Versturen...' : 'Aanmelden'}
        </Button>
      )}

      {errors && <p style={{ color: 'red', marginTop: '2rem' }}>{errors}</p>}
    </StyledForm>
  );
};

export default Form;
