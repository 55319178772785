import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import SectionWrapper from './SectionWrapper';
import ContentWrapper from './ContentWrapper';
import Title from './Title';
import Text from './Text';
import Form from './Form';

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  margin: 0 -2rem;
  padding: 4rem 2rem;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin: 0;
    padding: 7.7rem;
  }
`;

const Appointment = () => {
  return (
    <SectionWrapper>
      <Container>
        <Content>
          <ContentWrapper white>
            <Title>Maak een afspraak</Title>
            <Text>
              <p>
                Vul het formulier in om je aan te melden voor een van de
                spreekuren. Na je aanmelding krijg je een tijdslot toegekend
                waarin je persoonlijk jouw vragen kunt stellen aan een van de
                experts. Zij doen dit vrijwillig, dus deelnemen is helemaal
                gratis.
              </p>
            </Text>
          </ContentWrapper>
          <Form />
        </Content>
      </Container>
    </SectionWrapper>
  );
};

export default Appointment;
