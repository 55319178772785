import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${({ big }) => (big ? '13.5rem' : '6.7rem')};
  width: 100%;
  margin: 0 0 1.6rem;

  svg {
    position: absolute;
    right: 2.5rem;
    top: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  input,
  textarea {
    background: ${({ theme }) => theme.black};
    border-radius: 0;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: ${({ theme }) => theme.white};
    width: 100%;
    height: 100%;
    font-size: 1.8rem;
    line-height: inherit;
    font-family: inherit;
    padding: ${({ hasValue }) =>
      hasValue ? '1.5rem 0 0 2.3rem' : '1.5rem 2.3rem'};
    transition: all 0.25s;
    outline: none;

    &:focus {
      border: 2px solid ${({ theme }) => theme.white};
    }
  }

  select {
    appearance: none;
    background: ${({ theme }) => theme.black};
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0;
    padding: 1.5rem 2.3rem;
    color: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    font-size: 1.8rem;
    line-height: inherit;
    font-family: inherit;
    transition: all 0.25s;
    outline: none;

    option {
      color: white;
    }
  }

  textarea {
    padding: ${({ hasValue }) =>
      hasValue ? '3.5rem 0 0 2.3rem' : '2.75rem 2.3rem'};
  }

  label {
    position: absolute;
    color: rgba(255, 255, 255, 0.3);
    top: 2.5rem;
    left: 2.4rem;
    will-change: transform;
    transition: all 0.25s;
    pointer-events: none;

    ${({ theme, hasValue }) =>
      hasValue &&
      `
        transform: translateY(-70%);
        font-size: 1.1rem;
        left: 2.3rem;
      `};
  }
`;

const Field = ({ big, children, select }) => {
  const [hasValue, setHasValue] = useState(false);

  const handleChange = value => {
    value.length > 0 ? setHasValue(true) : setHasValue(false);
  };

  return (
    <Wrapper
      select={select}
      big={big}
      hasValue={hasValue}
      onChange={e => handleChange(e.target.value)}
    >
      {children}
    </Wrapper>
  );
};

export default Field;
